
import { defineComponent } from 'vue'
import DownloadMenu from '@/components/organisms/statistics/DownloadMenu.vue'

export default defineComponent({
  name: 'StatisticsPage',
  components: {
    DownloadMenu,
  },
})
